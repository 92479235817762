<template>
  <div>
    <div v-if="formulaLoading" class="d-flex align-items-center">
      <strong>{{ $t("loading") }}</strong>
      <b-spinner class="ml-auto" />
    </div>
    <div v-if="!formulaLoading">
      <b-card>
        <!-- Media -->
        <div class="d-flex justify-content-between flex-column flex-md-row">
          <b-media class="mb-2">
            <h3 class="mb-1">
              {{ formulaData.rasyon[0].rationname }}
            </h3>
            <h5>
              {{
                dayjs(formulaData.rasyon[0].tarih)
                  .locale(`${$i18n.locale}`)
                  .format("DD MMMM YYYY")
              }}
            </h5>
          </b-media>
          <div>
            <h3></h3>
          </div>
          <div class="ml-0 ml-md-2">
            <b-button
              v-b-modal.modalPrint
              variant="outline-primary"
              size="sm"
              class="mr-2"
            >
              <feather-icon icon="PrinterIcon" />
              {{ $t("print") }}
            </b-button>
          </div>
        </div>
        <!-- User Info: Input Fields -->
        <b-row>
          <b-col cols="4" xs="2" sm="3" md="2" lg="2">
            <b-form-group :label="$t('solvePrice')" label-for="price">
              <div id="price" class="h4">
                {{
                  formulaData.rasyon[0].rasyonfiyati == null
                    ? "0"
                    : parseFloat(formulaData.rasyon[0].rasyonfiyati)
                        .toFixed(getUserData().pricedecimal)
                        .replace(".", ",")
                }}
                {{ getUserData().currency }}
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Action Buttons -->
      </b-card>
      <b-card class="mt-1">
        <slot></slot>
      </b-card>
    </div>
    <b-modal
      id="modalPrint"
      :title="$t('printOptions')"
      centered
      size="xl"
      hide-footer
      class="modal-lg"
    >
      <print-modal :formula-data="formulaData"></print-modal>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BRow,
  BCol,
  BFormGroup,
  BCard,
  BSpinner,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import dayjs from "dayjs";
import tr from "dayjs/locale/tr";
import az from "dayjs/locale/az";
import ru from "dayjs/locale/ru";
import PrintModal from "./Components/ProductionPrintModal.vue";

export default {
  components: {
    BMedia,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BSpinner,
    PrintModal,
  },
  props: {
    formulaData: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      getUserData: getUserData,
      dayjs: dayjs,
      tr: tr,
      az: az,
      ru: ru,
    };
  },
  computed: {
    formulaLoading() {
      return this.$store.state.formulasModule.formulaLoading;
    },
  },

  methods: {
    async deleteFormula() {
      await this.$store.dispatch("formulasModule/deleteFormula", {
        formulaid: this.formulaData.id,
        plantid: this.$store.state.app.selectedPlantId,
        tur: this.$route.params.tur,
      });
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.m-small {
  margin-top: 0.2rem;
  @media screen and (max-width: 768px) {
    margin-left: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
  }
}
.bg-yellow {
  background-color: #fff820 !important;
  color: #5e5874 !important;
}
</style>
