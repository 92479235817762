<template>
  <div>
    <component :is="'b-card'" v-if="formulaData.hammaddeler == null">
      <h4 class="alert-heading">
        {{ $t("xFetchError", { value: $t("formula") }) }}
      </h4>
      <div class="alert-body">
        {{ $t("xNotFound", { value: $t("formula") }) }}
        <b-link class="alert-link" :to="{ name: 'plants' }"> </b-link>
      </div>
    </component>
    <div v-if="formulaData.hammaddeler != null">
      <production-formula-view-formula-info
        :key="formulaData.id"
        :formula-data="formulaData"
        class="mt-2 pt-75"
        :formula-view="formulaView"
      >
        <!-- <b-tabs v-if="formulaData" active pills>
          <b-tab>
            <template #title active>
              <IngredientSvg height="16" />
              <span class="d-none d-sm-inline">{{ $t("ingredients") }}</span>
            </template>
            <production-formula-view-formula-ingredient
              :ingredients="formulaData.hammaddeler"
            />
          </b-tab>
          <b-tab>
            <template #title active>
              <NutrientSvg height="16" />
              <span class="d-none d-sm-inline">{{ $t("nutrients") }}</span>
            </template>
            <production-formula-view-formula-nutrient
              :nutrients="formulaData.besinmaddeleri"
            />
          </b-tab>
        </b-tabs> -->

        <b-row class="mt-1">
          <b-col>
            <hammadde-table :items="formulaData.hammaddeler"></hammadde-table>
          </b-col>
          <b-col>
            <besin-maddesi-table
              :items="
                formulaData.besinmaddeleri.map((x) => ({
                  besinmaddesi: x.nutrientname,

                  ...x,
                }))
              "
            ></besin-maddesi-table>
          </b-col>
        </b-row>
      </production-formula-view-formula-info>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BLink } from "bootstrap-vue";
import router from "@/router";
import store from "@/store";
import ProductionFormulaViewFormulaInfo from "./ProductionFormulaViewFormulaInfo.vue";

import VueI18n from "@/libs/i18n";
import HammaddeTable from "./Components/HammaddeTable.vue";
import BesinMaddesiTable from "./Components/BesinMaddesiTable.vue";

export default {
  components: {
    BCard,
    BLink,
    ProductionFormulaViewFormulaInfo,
    HammaddeTable,
    BesinMaddesiTable,
    BRow,
    BCol,
  },

  data: function () {
    return {
      formulaView: {
        count: 0,
        caGrid: false,
        gaGrid: false,
        svGrid: false,
        syGrid: false,
        kacgunoncedogurduGrid: false,
        laktasyonGrid: false,
        minkabalar: false,
        dayspregnantGrid: false,
        buzagiagirligiGrid: false,
        kacgundursagiliyorGrid: false,
        buzagiyasiGrid: false,
        gebeliksuresiGrid: false,
        conditionscoreGrid: false,
        toplamgrupsayisiGrid: false,
        dogumakalangunGrid: false,
        rasyongunGrid: false,
        karkasrandimaniGrid: false,
      },
    };
  },
  computed: {
    formulaData() {
      return store.state.formulasModule.selectedProductionFormula;
    },
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == "productionFormulaView") {
      to.meta.breadcrumb = [];
      to.meta.breadcrumb.push({
        to: "/formulas",
        text: VueI18n.t(`plants`),
        active: true,
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t(`productionFormulas`),
        to: `/production-formulas`,
        active: false,
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t("formula"),
        active: true,
      });
    }
    next();
  },
  async mounted() {
    await store.dispatch("formulasModule/fetchProductionFormula", {
      id: router.currentRoute.params.formulaid,
      tur: router.currentRoute.params.tur,
      plantid: store.state.app.selectedPlantId,
    });

    // await store.dispatch("formulasModule/getFormulaIngredients");
    this.formulaTypeView();
  },
  methods: {
    async formulaTypeView() {},
  },
};
</script>

<style></style>
